.exports-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
}

.exports-modal-title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 25px;
}

.exports-table {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3px;
    height: calc(100% - 125px);
    margin-bottom: 30px;
}

.exports-table div {
    text-align: center;
}

.export-clip-single {
    width: 100%;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 12px;
    background-color: rgba(0,0,0,.35);
    border-radius: 3px;
}

.export-clip-single.expired {
    color: rgba(255, 255, 255, 0.5);
}

.export-submitted {
    width: 10%;
}

.export-source {
    width: 25%;
}

.export-preset {
    width: 8%;
}

.export-creator {
    width: 15%;
}

.export-expires {
    width: 12%;
}

.export-size {
    width: 10%;
}

.export-duration {
    width: 10%;
}

.export-download {
    width: 10%;
}

.export-delete {
    width: 3%;
}

.export-delete.icon {
    color: white;
}

.export-processing {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 42%;
}

.export-source.data {
    font-weight: 600;
    padding: 0 3px;
}

.export-size.data,
.export-duration.data {
    color: var(--light-blue);
}

.export-clip-single:not(.expired) .export-size.data,
.export-clip-single:not(.expired) .export-duration.data {
    font-weight: 700;
}

.remove-export-btn {
    font-size: 13px;
    cursor: pointer;
}

.export-msg-cont {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--button-green);
}

.export-processing-time-info {
    color: rgba(255, 255, 255, .7);
}

.exports-table-info {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    gap: 3px;
    color: rgba(255, 255, 255, .7);
    margin-bottom: 15px;
    font-size: 12px;
}

.download-expired {
    color: var(--warning-text-red);
    font-weight: 500;
}

.exports-table-info svg {
    font-size: 14px;
}

.no-exports-table {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    color: rgba(255, 255, 255, .7);
    font-style: italic;
    margin: 25px 0;
}

/* HOVER */
.remove-export-btn:hover {
    color: var(--dark-yellow);
}
.table-header-sort:hover {
    color: white;
}