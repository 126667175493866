.metadata-payment {
    display: flex;
}
.payment-plan-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 225px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 2px;
}

.payment-plan-single {
    width: 100%;
    padding: 5px 10px;
}

.payment-plan-type {
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0;
}

.price-merchant {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3px;
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 7px 0;
    margin-bottom: 5px;
}

.merchant-club-logo {
    height: 22px;
}

.payment-price-name {
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: var(--light-blue);
}

.payment-period-ended {
    margin-top: 7px;
    color: var(--dark-yellow);
    font-weight: 500;
}

.price-period-cont {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0;
}

.price-period-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.price-period-title {
    width: 95px;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
}

.price-period-days {
    font-weight: 600;
}

/* MEDIA QUERY */
@media screen and (max-width: 480px) {
    .price-merchant ,
    .price-period-title,
    .price-period-days,
    .price-period-until {
        font-size: 12px;
    }
    .payment-plan-cont {
        min-width: unset;
    }
    .price-merchant {
        padding: 5px 0;
    }
    .payment-price-name {
        font-size: 14px;
    }
    .price-period-cont {
        padding: 5px 8px;
    }
    .payment-period-ended {
        margin-top: 3px;
    }
}