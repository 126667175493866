.match-single,
.match-scoreboard,
.match-home,
.match-visiting,
.match-score,
.match-score-vs {
    display: flex;
}

a.match-single {
    text-decoration: unset;
    color: inherit;
}

.match-single,
.match-scoreboard,
.match-home,
.match-visiting {
    align-items: center;
}

.match-single {
    position: relative;
    height: 75px;
    padding: 0 7px;
    cursor: pointer;
    border-radius: 3px;
    background-color: rgba(0,0,0,0.45)
}
.match-single:first-child {
    margin-top: 5px;
}
.match-single:last-child {
    margin-bottom: 5px;
}

.match-date {
    width: fit-content;
    margin-top: 25px;
    margin-bottom: 10px;
    margin-left: 2px;
    padding: 1px 0;
    padding-left: 7px;
    border-left: 7px solid var(--dark-yellow);
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
}

.match-scoreboard {
    width: 100%;
    display: flex;
    justify-content: center;
}

.match-home,
.match-visiting {
    width: 225px;
    font-size: 13px;
}

.match-home {
    justify-content: flex-end;
}

.match-home > .match-club-name {
    justify-content: flex-start;
}

.match-score {
    letter-spacing: 3px;
}

.match-score-vs {
    width: 50px;
    justify-content: center;
}

.match-club-logo img {
    height: 32px;
    margin: 0 10px;
}

.match-time-and-live {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
}

.match-start-time {
    width: 40px;
    padding: 3px 0;
    text-align: center;
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    font-weight: 500;
    border-radius: 2px;
}

.shl-match .match-club-logo img {
    height: 30px;
}

.match-upcoming {
    width: fit-content;
    padding: 3px 4px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--light-blue);
    color: var(--light-blue);
}

.match-period-filter-cont {
    position: absolute;
    right: 2px;
    display: flex;
    gap: 10px;
    height: 100%;
    max-height: 40px;
}

.match-period-filter-cont.flex-grow {
    position: unset;
    flex-grow: 1;
    justify-content: flex-end;
}

.match-period-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 27px;
    padding-bottom: 1px;
    border: 2px solid rgba(255,255,255, 0.3);
    font-size: 12px;
    color: rgba(255,255,255, 0.5);
    border-radius: 2px;
    cursor: pointer;
}

.match-period-filter.active {
    color: var(--dark-yellow);
    border-color: var(--dark-yellow);
}

.weekly-highlights {
    margin-bottom: 10px;
}

/* HOVER */
.match-single:hover {
    background-color: var(--hover-blue);
}
.match-period-filter:not(.active):hover {
    color: rgba(255,255,255, 0.7);
}