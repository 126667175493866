.login-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 75px;
}

.login-container {
    display: flex;
    align-items: center;
    min-height: 320px;
    width: fit-content;
    gap: 50px;
    padding: 30px;
    background-color: #101525;
    border-radius: 2px;
}

.login-container form {
    width: 300px;
}

.reset-password-form {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container .error-msg {
    flex-direction: column;
    font-weight: bold;
    font-style: italic;
    color: red;
    text-align: center;
    min-height: 1em;
    width: 100%;
}
.login-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container button {
    width: 300px;
}

.login-build-version-text {
    color: grey;
    font-style: italic;
    font-size: small;
    margin-top: 20px;
}

.login-welcome {
    font-size: 27px;
    font-weight: 600;
}

.login-fotbollkonto-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
}

.login-input {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px 0;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 12px;
    border-radius: 2px;
}

.login-input label {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 9px;
    margin-bottom: 1px;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.8);
}

.login-input input,
.login-input input[type="password"] {
    width: 100%;
    background-color: unset;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.8);
    height: 28px;
    padding-left: 10px;
    outline: none;
    border-radius: 0;
    font-size: 15px;
}

.login-input input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
}

.login-with-username {
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.7);
}

.login-with-username.sef {
    cursor: pointer;
}

.login-logo-and-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.login-association-logo {
    height: 200px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}

.login-association-name {
    font-size: 22px;
    font-weight: 600;
}

.mam-long-name {
    font-size: 17px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 2px;
    margin-bottom: 32px;
}

.login-or {
    font-size: 14px;
    font-weight: bold;
}

.error-msg.login {
    min-height: 25px;
}

.forgot-password-btn {
    border: none;
    margin-top: 10px;
    padding: 2px;
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    color: var(--light-blue);
    cursor: pointer;
}

/* RESET PASSWORD */
.reset-password-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 75px;
    background-color: var(--dark-blue);
}

.reset-guide-message {
    font-size: 14px;
    margin-bottom: 12px;
}

input.reset-password-input {
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 5px 0;
    border-radius: 2px;
    border: none;
}

input.reset-password-input.token {
    margin-top: 12px;
    margin-bottom: 32px;
}

.reset-password-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 25px;
    min-height: 150px;
}

.back-to-login {
    font-size: 14px;
    font-weight: 600;
    color: var(--light-blue);
    margin-top: 20px;
    cursor: pointer;
}

.reset-password-success {
    font-size: 14px;
    font-weight: 600;
}

.reset-success-icon {
    font-size: 40px;
    color: var(--button-green);
}

.sending-to-email {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--light-blue);
    margin-top: 5px;
}

.sending-to-email-loading {
    position: relative;
    width: 22px;
    height: 22px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    overflow: hidden;
}

.sending-to-email-loading::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
        var(--light-blue) 90deg,
        transparent 0deg
    );
    animation: rotate 1s linear infinite;
}

.sending-to-email-loading::after {
    content: "";
    position: absolute;
    inset: 3px;
    background: #101525;
    border-radius: 50%;
}

/* HOVER */
.forgot-password-btn:hover,
.back-to-login:hover,
.login-with-username.sef:hover {
    background-color: none;
    filter: brightness(80%);
}

@media screen and (max-width: 768px) {
    .login-container {
        flex-direction: column;
        gap: 35px;
        padding: 30px 15px;
    }
    .login-association-logo {
        height: 150px;
        margin-bottom: 0;
    }
    .login-association-name {
        font-size: 18px;
    }
    .login-page {
        padding-bottom: 0;
    }
    .mam-long-name {
        margin-bottom: 25px;
    }
    .login-input input,
    .login-input input[type="password"],
    .login-input input::placeholder {
        font-size: 14px;
    }
}