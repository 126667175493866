.poi-tracker-cont > * {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.poi-tracker-cont > div > div:first-child {
    min-width: 110px;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-item {
    color-scheme: light;
    margin-right: 10px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    user-select: none;
    cursor: pointer;
}

.checkbox-item input[type="checkbox"]:checked ~ span {
    color: var(--dark-yellow);
}

.checkbox-item input[type="checkbox"] {
    margin-left: 0;
    position: relative;
    cursor: pointer;
}

.poi-tracker-frame-info {
    font-family: monospace;
    font-size: 13px;
    color: #ffffff;
    position: absolute;
    top: 3px;
    right: 3px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    font-weight: bold;
    pointer-events: auto;
    cursor: text;
    text-align: right;
}

@keyframes zoomInOut {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.2); }
}

.animated-attention-text {
    color: red;
    font-size: 16px;
    animation: zoomInOut 0.5s ease-in-out;
}

.poi-entity {
    position: absolute;
    border: 1px solid #fafa79;
    pointer-events: none;
    background: transparent;
}

.poi-entity::after {
    content: attr(data-info);
    position: absolute;
    white-space: pre;
    left: 100%;
    top: 0;
    margin-left: 1px;
    color: white;
    font-size: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    /*padding: 1px;*/
    pointer-events: none;
}

.poi-entity-ball {
    border: 2px solid red;
    border-radius: 50%;
}

.poi-entity-keeper {
    border-color: #006fff
}

.poi-entity-referee {
    border-color: white
}

.audio-bar-graph-container {
    position: absolute;
    width: 190px;
    height: 50px; /* Adjust viewport height */
    overflow: hidden; /* Crops the canvas to create the "zoomed-in" effect */
    background-color: rgba(0, 0, 0, 0.5);
}

.audio-bar-viewport {
    position: absolute;
    width: 380px;
    height: 100%;
}

.audio-bar-canvas {
    width: 100%; /* Ensure the canvas stretches fully within the viewport */
    height: 100%;
}

.current-frame-indicator {
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: blue;
    left: calc(50% - 1px); /* Centered indicator */
    top: 0;
    bottom: 0;
}

.current-audio-rms {
    position: relative;
    font-family: monospace;
    font-size: 13px;
    color: #ffffff;
    margin: 1px 5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    font-weight: bold;
    pointer-events: auto;
    cursor: text;
}

.current-audio-rms span {
    float: right;
}

button.jump-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.5rem;
}
