.stream-video {
    padding-top: 10px;
}

.stream-status-banner {
    position: relative;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
}

.stop-stream {
    position: absolute;
    right: 0;
    top: 0;
}

.stream-details-and-game {
    width: 100%;
    height: fit-content;
}

.sd-cont {
    padding: 7px;
    background: #101525;
    box-shadow: 0 0 12px black;
    margin-bottom: 30px;
}

.sd-duration,
.es-duration {
    display: flex;
    color: var(--light-blue);
    font-weight: bold;
    gap: 5px;
}

.sd-duration.center {
    text-align: center;
}

/* Game Details */
.stream-game-cont {
    padding: 7px;
    background: #101525;
    box-shadow: 0 0 12px black;
    margin-bottom: 30px;
}

.sd-game-details {
    display: flex !important;
    align-items: center !important;
}

.sd-game-details div {
    height: 100%;
    display: flex;
    align-items: center;
}

.sgd-logo {
    height: 27px;
    margin: 0 7px;
}

.stream-to-match {
    margin-top: 25px;
    text-decoration: none;
    color: white;
}

.stream-to-match:visited {
    color: unset;
}

.stream-no-game {
    color: rgba(255, 255, 255, 0.5);
}

.go-to-video {
    margin-bottom: 24px;
}

.go-to-video a{
    text-decoration: none;
    color: white;
    font-size: .9em;
}

.stopping-stream-banner {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

/* Edit Stream */
.edit-stream-cont {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 525px;
    padding: 12px 0;
}

.video-metadata-cont {
    padding: 7px;
    background: #101525;
    box-shadow: 0 0 12px black;
    margin-bottom: 30px;
}

.edit-stream-cont.playlist {
    max-width: unset;
}

.edit-stream-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin-top: 35px;
    height: calc(100% - 100px);
    gap: 30px;
}

.edit-stream-cont.playlist .edit-stream-form {
    justify-content: unset;
}

.edit-stream-title {
    text-align: center;
    font-size: 1.5em;
}

.stream-schedule-active {
    font-size: 14px;
}

/* HOVER */
.sd-edit-icon:hover {
    color: var(--dark-yellow);
}

/* MEDIA QUERY */
@media screen and (max-width: 768px) {
    .edit-stream-cont {
        padding: 0;
        margin: 5px 0;
    }
    .stream-status-banner {
        flex-direction: column;
        align-items: center;
        gap: 8px;
        height: fit-content;
        margin-bottom: 12px;
    }
    .stop-stream {
        position: static;
        width: 100%;
    }
    .stop-stream button {
        width: 100%;
    }
    .edit-stream-title {
        font-size: 17px;
        font-weight: 600;
    }
    .sd-game-details {
        font-size: 11px;
    }
    .sd-game-details img {
        height: 20px;
    }
}