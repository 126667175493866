
.listing-filter-textbox {
    display: flex;
    flex: 1;
    font-size: 1rem;
}
.listing-filter-textbox::placeholder {
    color: darkgrey;
}
.listing-filter-textbox:focus-visible {
    outline: 1px solid white;
    border-bottom: 2px solid transparent;
}

.filter-dropdown {
    position: relative;
    width: 135px;
    padding: 3px 5px;
    border: 1px solid rgba(255,255,255, 0.2);
    border-radius: 2px;
    font-size: 13px;
    cursor: pointer;
}

.filter-dropdown.open {
    border-color: var(--dark-yellow);
}

.filter-dropdown.narrow {
    width: 60px;
}

.filter-dropdown.wide {
    width: 175px;
}

.filter-title {
    font-size: 10px;
    color: rgba(255,255,255, 0.5);
    margin-bottom: 3px;
    color: var(--dark-yellow);
}

.filter-selected {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.filter-dropdown-list {
    display: none;
}

.open .filter-dropdown-list {
    position: absolute;
    top: 42px;
    left: -1px;
    width: calc(100% + 2px);
    height: fit-content;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    background-color: black;
    z-index: 1;
    overflow-y: auto;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 7px;
    font-size: 12px;
}

.dropdown-item.active {
    color: var(--dark-yellow);
}

.filter-dropdown.disabled {
    cursor: auto;
}

.disabled .filter-selected {
    padding-top: 1px;
    padding-bottom: 2px;
    color: rgba(255,255,255, 0.5);
}

/* HOVER */
.filter-dropdown:not(.disabled):hover .filter-selected {
    color: rgba(255,255,255, 0.8);
}

.dropdown-item:hover {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}