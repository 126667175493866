.percentage-progress-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 45px;
}

.percentage-progress {
    position: relative;
    width: 42px;
    height: 42px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    overflow: hidden;
}

.percentage-progress-bar {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
}

.percentage-progress-percentage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    height: 34px;
    width: 34px;
    font-size: 12px;
    z-index: 10;
    background-color: #101525;
    border-radius: 25px;
    color: var(--light-blue);
    font-weight: 500;
}

.upload .percentage-progress-percentage {
    background-color: var(--dark-blue);
}

.percentage-info-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.percentage-progress-status {
    height: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    font-size: 13px;
    text-transform: capitalize;
    color: var(--light-blue);
    font-weight: 600;
}

.percentage-progress-status.center {
    height: 100%;
    align-items: center;
}

.percentage-processing-time-info {
    color: var(--grey);
    font-style: italic;
}

@keyframes blink {
    50% { color: transparent };
}
.percentage-progress-title-dot { 
    animation: 1s blink infinite;
    font-size: 18px;
    margin-left: 2px;
}
.percentage-progress-title-dot:nth-child(1) { 
    animation-delay: 250ms;
    margin-left: 3px;
}
.percentage-progress-title-dot:nth-child(2) { 
    animation-delay: 500ms;
}
.percentage-progress-title-dot:nth-child(3) { 
    animation-delay: 750ms;
}

.upload-message {
    margin: 10px 0;
}

.upload-message-failed {
    display: flex;
    color: var(--warning-text-red);
    font-weight: 600;
    margin: 10px 0;
}

.upload-message-title {
    min-width: 105px;
}