.payment-cont {
    width: 100%;
}

.enabled-payment {
    width: 100%;
}

.enabled-payment-title {
    display: flex;
    align-items: center;
    height: 52px;
    gap: 7px;
}

.payment-options-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 15px;
}

.payment-method-title {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 14px;
}

.payment-method-title:not(.disabled) {
    cursor: pointer;
}

.payment-method-title.disabled {
    color: rgba(255, 255, 255, 0.5);
}

.payment-type {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #101525;
    padding: 12px 10px;
    border-radius: 3px;
}

.payment-ppv {
    display: flex;
    gap: 32px;
}

.input-container.payment-dropdown {
    width: 250px;
    margin-top: 5px;
}

.payment-option-list {
    background-color: black;
}

.payment-option-single {
    gap: 5px;
    padding: 10px;
    cursor: pointer;
}

.payment-option-single.active {
    background-color: var(--light-blue);
}

.payment-option-single.no-merchant {
    color: rgba(255, 255, 255, 0.8);
}

.select-merchant-for-price {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
}

.payment-period-until {
    color: rgba(255, 255, 255, 0.7);
    margin-top: 7px;
}

/* Disable payment edit */
.payment-cont.disabled .option-checkbox:hover {
    cursor: unset;
    border-color: rgba(255, 255, 255, 0.5);
}

.payment-cont.disabled .option-checkbox.active:hover {
    cursor: unset;
    border-color: var(--dark-yellow);
}

.payment-cont.disabled .upload-dropdown-title:hover {
    cursor: auto;
}

.edit-payment-info {
    display: flex;
    align-items: center;
    gap: 5px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 15px;
}

.edit-payment-info svg {
    margin-top: -2px;
}

/* HOVER */
.payment-option-single:hover {
    background-color: var(--light-blue);
}
.payment-method-title:not(.disabled):hover {
    color: var(--dark-yellow);
}

/* MEDIA QUERY */
@media screen and (max-width: 890px) {
}