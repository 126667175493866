.search-cont {
    width: 100%;
    height: 100%;
}

.tag-based-search-cont {
    height: calc(100% - 20px);
    width: 100%;
    background-color: var(--dark-blue);
    z-index: 10;
    overflow: hidden;
}

.search-field-cont {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 2px;
}

.search-field-cont svg {
    font-size: 20px;
}

.search-field-and-suggestions {
    position: relative;
    width: calc(100% - 80px);
}

.search-field {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    height: 42px;
    gap: 5px;
    padding: 0 5px;
    border-radius: 3px;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.1);
    /* border: 1px solid red; */
}

.search-field.active {
    border-color: var(--light-blue);
}

.search-field input[type="text"] {
    height: 30px;
    padding: 0 5px;
    flex-grow: 1;
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
}

.search-field input::placeholder {
    font-weight: 500;
}

.search-field::-webkit-scrollbar {
    height: 5px;
    border-radius: 10px;
}

.search-tag {
    float: left;
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 5px;
    padding: 5px 7px;
    border: 1px solid rgba(255,255,255,0.25);
    border-radius: 3px;
}

.search-tag svg {
    margin-top: 1px;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
}

.search-tag-title {
    color: rgb(255, 125, 0);
}

.search-tag-value {
    max-width: 125px;
    width: fit-content !important;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-suggestion-cont {
    position: absolute;
    top: 35px;
    width: 100%;
    height: fit-content;
    max-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    padding: 10px;
    background-color: #101525;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 3px;
    z-index: 1;
    overflow: auto;
}

.result-title {
    color: var(--grey);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    padding: 0 3px;
    padding-bottom: 3px;
    margin-bottom: 1px;
    border-bottom: 1px solid rgba(255,255,255,0.25);
}

.result-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
}

.result-item.in-selection {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}

.result-item svg {
    font-size: 12px;
    margin-left: 15px;
}

.suggestion-no-results {
    color: rgba(255,255,255,0.35);
    padding-left: 3px;
    font-style: italic;
}

.suggestion-game-date {
    position: absolute;
    right: 0;
    text-align: left;
    min-width: 70px;
    color: var(--grey);
    font-style: italic;
    font-size: 11px;
}

.close-suggestion {
    display: flex;
    justify-content: center;
    margin-top: -7px;
}

.close-suggestion-btn {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 3px;
    font-size: 11px;
    color: rgba(255,255,255,0.5);
    cursor: pointer;
}

.close-suggestion-btn svg {
    font-size: 15px;
}

.no-results-found {
    margin-top: 45px;
    text-align: center;
    color: var(--grey);
    font-style: italic;
}

.search-button {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 2px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.search-button svg {
    font-size: 17px;
}

.search-info-cont {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-top: 7px;
}

.search-info-btn {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 3px;
    color: var(--grey);
    font-size: 11px;
    cursor: pointer;
    font-style: italic;
}

.search-info-btn svg {
    font-size: 17px;
    margin-top: -1px;
    padding-left: 2px;
}

.search-info {
    position: absolute;
    top: 25px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - 25px);
    max-width: 750px;
    gap: 5px;
    padding: 10px;
    background-color: black;
    border-radius: 3px;
    z-index: 1;
}

.search-info.align-center {
    right: 0;
    transform: translateX(-25%);
}

.search-info img {
    width: 100%;
    margin-top: 10px;
}

.close-search-info {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 17px;
    cursor: pointer;
    color: var(--grey);
    transform: translate(40%, -40%);
}

.no-results-img {
    width: 100%;
    display: flex;
    justify-content: center;
}
.no-results-img img {
    width: 450px;
}

.search-keyboard-tips {
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -10px;
    font-size: 11px;
    color: rgba(255,255,255,0.4);
}

.search-keyboard-tips svg {
    font-size: 18px;
    margin: 0 2px;
    margin-top: 2px;
}

.smaller-period-filter {
    width: fit-content;
    margin-bottom: 7px;
}

/* SEARCH RESULTS */
.search-results-cont {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 125px);
    max-height: 900px;
    width: 100%;
    margin-top: 10px;
    gap: 3px;
}

.search-results-cont.free-search {
    height: calc(100% - 70px);
}

.search-results-list {
    height: 100%;
    overflow-y: auto;
}

.search-results-cont.filter {
    filter: brightness(40%);
}

.search-results-number {
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    color: var(--grey);
}

.clear-results {
    display: flex;
    align-items: center;
    gap: 2px;
    margin: 0 auto;
    margin-top: 12px;
    color: var(--grey);
    cursor: pointer;
    font-size: 12px;
}

.clear-results svg {
    margin-bottom: 1px;
    font-size: 20px;
}

.open-search-icon {
    font-size: 14px;
    margin-bottom: -2px;
    margin-right: 3px;
}

.loading-search-results {
    height: 250px;
}

.search-model-cont {
    position: relative;
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 7px;
    width: 145px;
    padding: 3px;
}

.selected-search-model {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.search-model-options {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 145px;
    z-index: 10;
    background-color: #101525;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.search-model-option-single {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 5px;
    cursor: pointer;
}

.search-model-option-single.active {
    color: var(--dark-yellow);
}

input.free-search-input {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    flex-grow: 1;
    height: 42px;
    padding: 0 7px;
    border: 1px solid transparent;
    border-radius: 3px;
}

/* HOVER */
.search-tag svg:hover,
.search-button:hover {
    color: var(--dark-yellow);
}

.result-item:hover,
.result-item:hover .suggestion-game-date,
.search-model-option-single:hover {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}

.search-info-btn:hover,
.close-search-info:hover,
.close-suggestion-btn:hover,
.clear-results-btn:hover,
.clear-results:hover {
    color: white;
}