.poi-rectangle {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    border: 2px solid;
    transform: translateX(-50%);
    pointer-events: none;
    background: transparent;
}

.dimming-left, .dimming-right {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
}

.dimming-left {
    left: 0;
}

.dimming-right {
    right: 0;
}

.poi-cross {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    font-size: 16px;
    color: #ffffff;
}

.poi-cross::before {
    content: '\2715';
}

.poi-rectangle-cont {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: grab;
}