.event-listing {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
    position: relative;
}
.event-filters {
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    margin-right: 25px;
    margin: 5px 0;
    gap: 5px;
}
.event-filters > div {
    display: flex;
}
.inline-shirt {
    display: inline-block;
    line-height: 0.8em;
    min-width: 1.4em;
    height: 1em;
    border-radius: 10%;
    border: 1px solid rgba(255,255,255,0.35);
    text-align: center;
}
.filter-controls {
    position: absolute;
    right: 0;
    top: 0;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    font-size: 12px;
}
.filter-controls > button {
    font-size: 1em;
    padding: 0.26em 0.4em;
}