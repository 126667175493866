.video-entry-metadata,
.game-time,
.video-entry-metadata > i {
    display: flex;
    align-items: center;
}

.video-entry-thumb,
.video-entry img,
.metadata-and-details,
.video-entry-metadata > i {
    height: 100%;
}

.video-entry-metadata,
.game-time {
    width: 100%;
}

.video-entry-is-unlisted {
    color: var(--dark-yellow);
    margin: 0 7px;
}

.video-entry-holdback {
    color: var(--warning-text-red);
    margin: 0 7px;
    font-weight: bold;
}

.video-entry {
    display: flex;
    align-items: center;
    height: 75px;
    margin-top: 5px;
    padding-right: 10px;
    font-size: 15px;
    background-color: #101525;
    border-radius: 3px;
    cursor: pointer;
}

.video-entry.active {
    background-color: var(--hover-blue);
}

.video-entry-thumb {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.video-entry-thumb img {
    width: 128px;
    height: 73px;
}

.video-entry-logos {
    display: flex;
    justify-content: center;
    width: 45px;
    height: 95%;
    margin-right: 10px;
    overflow: hidden;
}

.video-entry-logos img {
    height: 75px;
    transform: rotate(18deg) translateX(-20%) translateY(12%);
    filter: brightness(40%);
}

.shl .video-entry-logos img {
    height: 75px;
    transform: rotate(18deg) translateX(-20%) translateY(12%);
    filter: brightness(40%);
}

.metadata-and-details {
    padding-top: 3px;
    width: 80%;
}

.video-without-video-icon {
    width: 150px;
    font-size: 30px;
}

.video-entry-icons {
    display: flex;
    justify-content: center;
    font-size: 22px;
    width: 32px;
}

.video-entry-metadata {
    height: 30%;
    font-size: 12px;
}

.game-time {
    justify-content: center;
    width: 27px;
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    background-color: var(--light-blue);
    color: black;
    border-radius: 2px;
}

.shl .game-time {
    width: 45px;
    font-size: 11px;
}

.video-entry-details {
    font-size: 13px;
}

.video-duration {
    position: absolute;
    bottom: 1px;
    left: 0;
    font-size: .8em;
    background-color: rgba(0, 0, 0, 0.555);
    padding: 2px 3px;
    text-shadow: #000 0 0 2px, #000 0 0 2px, #000 0 0 2px, #000 0 0 2px;
}

.video-entry-metadata > i {
    margin-right: 9px;
    color: #bbbbbb;
}

.video-checkbox-action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.video-checkbox-action div{
    height: 80%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.video-checked-box,
.video-unchecked-box {
    font-size: 30px;
    padding: 7px;
}

.video-checked-box {
    color: var(--dark-yellow);
}

.no-checkbox-action {
    width: 10px;
}

.video-entry-info-title {
    height: 100%;
    width: 100%;
    padding: 5px 0;
}

.entry-date-and-game {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
    height: 20px;
    font-size: 11px;
    font-style: italic;
    color: var(--grey);
}

.is-event .entry-date-and-game {
    justify-content: flex-end;
}

.entry-date-and-game div {
    display: flex;
    align-items: center;
    gap: 4px;
}

.entry-date-game-icon {
    font-size: 17px;
    color: var(--light-blue);
}

.video-entry-top-bottom {
    width: 100%;
    height: 100%;
    padding: 5px 0;
}

.video-entry-top {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    /* border: 1px solid red; */
}

.video-entry-bottom {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    /* border: 1px solid green; */
}

.video-entry-date,
.video-entry-game {
    min-width: 90px;
}

.shl .video-entry-game {
    width: 100px;
}

/* HOVER */
.video-entry:hover {
    background-color: var(--hover-blue);
}
.video-checkbox-action div:hover > .video-unchecked-box {
    color: var(--dark-yellow);
}