.export-record-cont {
    width: 100%;
}

.export-record-table.export-summary {
    max-width: 450px;
}

.export-summary-data {
    display: flex;
    align-items: center;
    height: 37px;
    margin: 3px 0;
    background-color: #101525;
}

.export-summary-data.total {
    font-weight: 600;
    color: var(--dark-yellow);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.export-summary-type {
    width: 40%;
}

.export-summary-count {
    width: 30%;
}

.export-summary-size {
    width: 30%;
}

.export-record {
    margin-top: 15px;
}

.export-record-table {
    text-align: center;
    margin-bottom: 25px;
}

.export-record-list {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.export-record-single {
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 13px;
    background-color: rgba(0,0,0,.35);
    border-radius: 3px;
}

.export-record-date {
    width: 20%;
}

.export-record-user {
    width: 20%;
}

.export-record-size {
    width: 15%;
}

.export-record-format {
    width: 15%;
}

.export-record-type {
    width: 15%;
}

.export-record-status {
    width: 15%;
}

.export-record-size.data {
    color: var(--light-blue);
    font-weight: 600;
}

.export-record-status.success {
    color: var(--button-green);
    font-weight: 600;
}

.no-export-record {
    margin-top: 50px;
    text-align: center;
    font-style: italic;
    color: var(--grey);
}

.export-record-filters-cont {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.show-all-exports {
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    margin-top: 15px;
}

.show-all-exports svg {
    font-size: 20px;
    margin-top: 2px;
}

.show-all-exports.show svg {
    transform: rotate(90deg);
}

/* HOVER */
.show-all-exports:hover {
    filter: brightness(80%);
}