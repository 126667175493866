.generate-highlights-btn {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 15px;
}

.generate-highlights-cont {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 10px 5px;
}

.generate-highlights-title {
    font-size: 15px;
    text-align: center;
    margin-bottom: 25px;
}

.custom-highlights-title {
    display: flex;
    align-items: center;
    height: 52px;
    gap: 7px;
}

.duration-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
}

.custom-highlights-cont {
    width: 100%;
}

.to-existing-video-edit {
    color: var(--light-blue);
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
}

.generate-highlights-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.generate-highlights-club {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    width: 40%;
    font-size: 17px;
    font-weight: 600;
    color: rgba(255, 255, 255, .75);
}

.generate-highlights-club.away {
    justify-content: flex-start;
}

.generate-highlights-club img {
    height: 40px;
}

.generate-highlights-score {
    display: flex;
    align-items: center;
}

.generate-highlights-score div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: 17px;
    background-color: #101525;
    color: rgba(255, 255, 255, .75);
}

.pre-game-highlights-date {
    margin: 0 auto;
    text-align: center;
    width: 85px;
    padding: 2px 0;
    font-size: 11px;
    font-weight: 500;
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
    margin-bottom: 5px;
}

.generate-highlights-type-cont {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
    margin-top: 32px;
}

.generate-highlights-type {
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 7px 10px;
    height: 70px;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    font-size: 15px;
    background-color: #101525;
}

.generate-highlights-type.active {
    border-color: var(--dark-yellow);
}

.generate-highlights-team {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 7px;
    font-size: 14px;
}

.generate-highlights-team img {
    height: 30px;
}

.generate-highlights-publish {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 12px;
}

.flex-grow {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* HOVER */
.highlights-event-single:hover,
.to-existing-video-edit:hover {
    color: var(--dark-yellow);
}

.generate-highlights-type:hover {
    border-color: var(--dark-yellow);
}